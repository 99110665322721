// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("tabler/js/require.min")
// requirejs.config({
//   baseUrl: '.'
// });
// require("tabler/js/dashboard")

import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'cropperjs/dist/cropper.css'

import Vue from 'vue/dist/vue.esm'

// import TurbolinksAdapter from 'vue-turbolinks'
// Vue.use(TurbolinksAdapter)

import axios from 'axios'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content

import { ButtonPlugin, ModalPlugin, ToastPlugin } from 'bootstrap-vue'
Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)

import SiteForm from '../components/SiteForm'
Vue.component('site-form', SiteForm)
import SubscriptionForm from '../components/SubscriptionForm'
Vue.component('subscription-form', SubscriptionForm)

// document.addEventListener('turbolinks:load', () => {
document.addEventListener('DOMContentLoaded', () => {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  new Vue({
    el: '#application',
    created() {
      axios.interceptors.response.use(response => {
        const message = response.headers['x-message-content']
        if (message) {
          this.toast(decodeURIComponent(escape(message)))
        }
        return response
      })
    },
    mounted() {
      const flash = JSON.parse(this.$el.dataset.flash)
      if (flash.notice) {
        this.toast(flash.notice)
      }
      if (flash.alert) {
        this.toast(flash.alert, { variant: 'danger' })
      }
    },
    methods: {
      interpolate(message, replacements) {
        let result = message
        for (let [key, value] of Object.entries(replacements)) {
          result = result.replace(`%{${key}}`, value)
        }
        return result
      },
      toast(message, { title = 'Information', variant = 'success' } = {}) {
        const vNodesTitle = this.$createElement(
          'div',
          [
            this.$createElement('i', { class: 'fe fe-info mr-2' }, ''),
            this.$createElement('span', {}, title)
          ]
        )
        this.$bvToast.toast(message, {
          title: [vNodesTitle],
          toaster: 'b-toaster-bottom-left',
          variant: variant,
          autoHideDelay: 5000,
          solid: true
        })
      }
    }
  })
})
