<template>
  <ValidationObserver ref="validationObserver" v-slot="{ invalid, dirty, pristine, touched }" tag="form">
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.nurse_section_title }}
        </h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-9">
            <h3 class="card-title">
              {{ translations.site_form.nurse_civility_section_title }}
            </h3>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">
                    {{ translations.site_form.nurse_gender_label }}
                  </label>
                  <select class="form-control" name="nurse_gender" v-model="model.nurse_gender">
                    <option v-for="(label, value) in translations.nurse_gender" :value="value">{{ label }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">
                    {{ translations.site_form.nurse_first_name_label }}
                    <span class="form-required">*</span>
                  </label>
                  <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
                    <input type="text" class="form-control" :class="invalid && classes" name="nurse_first_name" v-model.trim="model.nurse_first_name">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">
                    {{ translations.site_form.nurse_last_name_label }}
                    <span class="form-required">*</span>
                  </label>
                  <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
                    <input type="text" class="form-control" :class="invalid && classes" name="nurse_last_name" v-model.trim="model.nurse_last_name">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    <ValidationProvider>
                      <input type="checkbox" name="approved" class="custom-switch-input" v-model="model.approved">
                      <span class="custom-switch-indicator"></span>
                      <span class="custom-switch-description">
                        {{ translations.site_form.nurse_approved_label }}
                      </span>
                    </ValidationProvider>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">
                    {{ translations.site_form.nurse_city_label }}
                    <span class="form-required">*</span>
                  </label>
                  <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
                    <input type="text" class="form-control" :class="invalid && classes" v-model.trim="model.nurse_city">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label">
                    {{ translations.site_form.nurse_zip_code_label }}
                    <span class="form-required">*</span>
                  </label>
                  <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
                    <input type="text" class="form-control" :class="invalid && classes" v-model.trim="model.nurse_zip_code">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 text-center">
            <h3 class="card-title">
              {{ translations.site_form.nurse_avatar_section_title }}
            </h3>
            <div class="form-group">
              <avatar-form
                v-model="model.nurse_avatar"
                :src="avatarUrl"
                :default-src="defaultAvatarUrl"
                :upload-url="directUploadsUrl"
                @input="$refs.validationObserver.flags.dirty = true"
                @uploading="submitting = true"
                @uploaded="submitting = false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title">
          {{ translations.site_form.contact_section_title }}
          <span class="form-required">*</span>
        </h3>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.contact_mobile_phone_label }}
                <span class="float-right">
                  {{ translations.site_form.contact_preferred_mode_label }}
                </span>
              </label>
              <div class="input-group">
                <ValidationProvider immediate :rules="{ required: model.contact_preferred_mode == 'contact_mobile_phone', regex: /^\+?[\d\s]+$/ }" v-slot="{ invalid, classes, errors }">
                  <input
                    type="tel"
                    class="form-control"
                    :class="invalid && classes"
                    name="contact_mobile_phone"
                    v-model.trim="model.contact_mobile_phone">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </ValidationProvider>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <ValidationProvider>
                      <input type="radio" name="contact_preferred_mode" value="contact_mobile_phone" v-model="model.contact_preferred_mode">
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.contact_office_phone_label }}
              </label>
              <div class="input-group">
                <ValidationProvider immediate :rules="{ required: model.contact_preferred_mode == 'contact_office_phone', regex: /^\+?[\d\s]+$/ }" v-slot="{ invalid, classes, errors }">
                  <input
                    type="tel"
                    class="form-control"
                    :class="invalid && classes"
                    name="contact_office_phone"
                    v-model.trim="model.contact_office_phone">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </ValidationProvider>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <ValidationProvider>
                      <input type="radio" name="contact_preferred_mode" value="contact_office_phone" v-model="model.contact_preferred_mode">
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.contact_email_label }}
              </label>
              <div class="input-group">
                <ValidationProvider immediate :rules="{ required: model.contact_preferred_mode == 'contact_email' }" v-slot="{ invalid, classes, errors }">
                  <input
                    type="email"
                    class="form-control"
                    :class="invalid && classes"
                    name="contact_email"
                    v-model.trim="model.contact_email">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </ValidationProvider>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <ValidationProvider>
                      <input type="radio" name="contact_preferred_mode" value="contact_email" v-model="model.contact_preferred_mode">
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title">
          {{ translations.site_form.languages_section_title }}
          <span class="form-required">*</span>
        </h3>
        <div class="row">
          <div class="col-6 col-md-3" v-for="(label, code) in translations.language">
            <label class="custom-control custom-checkbox">
              <ValidationProvider>
                <input type="checkbox" class="custom-control-input" name="languages" :value="code" v-model="model.languages">
                <span class="custom-control-label">
                  <i :class="`flag flag-${code} mr-2`"></i>
                  {{ label }}
                </span>
              </ValidationProvider>
            </label>
          </div>
        </div>
        <div class="alert alert-icon alert-danger mt-3" v-show="languagesAlert">
          <i class="fe fe-alert-triangle"></i>
          {{ translations.site_form.languages_error_empty }}
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title">
          {{ translations.site_form.payment_modes_section_title }}
          <span class="form-required">*</span>
        </h3>
        <div class="row">
          <div class="col-sm-3" v-for="(label, value) in translations.payment_mode">
            <div class="form-group">
              <label class="custom-control custom-checkbox">
                <ValidationProvider>
                  <input type="checkbox" class="custom-control-input" name="payment_modes" :value="value" v-model="model.payment_modes">
                  <span class="custom-control-label">
                    <i
                      class="fa  mr-1"
                      :class="{'fa-drivers-license-o': value == 'third_party', 'fa-credit-card': value == 'credit_card', 'fa-pencil-square-o': value == 'check', 'fa-money': value == 'cash'}">
                    </i>
                    {{ label }}
                  </span>
                </ValidationProvider>
              </label>
            </div>
          </div>
        </div>
        <div class="alert alert-icon alert-danger" v-show="paymentModesAlert">
          <i class="fe fe-alert-triangle"></i>
          {{ translations.site_form.payment_modes_error_empty }}
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.office_section_title }}
        </h2>
      </div>
      <div class="card-body">
        <label class="form-label">
          {{ translations.site_form.office_consultation_label }}
        </label>
        <div class="selectgroup">
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="office_consultation" :value="true" class="selectgroup-input" v-model="model.office_consultation">
              <span class="selectgroup-button">Oui</span>
            </ValidationProvider>
          </label>
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="office_consultation" :value="false" class="selectgroup-input" v-model="model.office_consultation">
              <span class="selectgroup-button">Non</span>
            </ValidationProvider>
          </label>
        </div>
      </div>
      <div class="card-body" v-show="model.office_consultation">
        <h3 class="card-title">
          {{ translations.site_form.office_address_section_title }}
        </h3>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.office_street_label }}
                <span class="form-required">*</span>
              </label>
              <ValidationProvider :rules="{ required: model.office_consultation }" v-slot="{ invalid, classes, errors }">
                <input type="text" class="form-control" :class="invalid && classes" name="office_street" v-model.trim="model.office_street">
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.office_zip_code_label }}
                <span class="form-required">*</span>
              </label>
              <ValidationProvider :rules="{ required: model.office_consultation }" v-slot="{ invalid, classes, errors }">
                <input type="text" class="form-control" :class="invalid && classes" name="office_zip_code" v-model.trim="model.office_zip_code">
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.office_city_label }}
                <span class="form-required">*</span>
              </label>
              <ValidationProvider :rules="{ required: model.office_consultation }" v-slot="{ invalid, classes, errors }">
                <input type="text" class="form-control" :class="invalid && classes" name="office_city" v-model.trim="model.office_city">
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-show="model.office_consultation">
        <h3 class="card-title">
          {{ translations.site_form.office_opening_hours_section_title }}
          <span class="form-required">*</span>
        </h3>
        <label class="form-label">
          {{ translations.site_form.split_opening_times_label }}
        </label>
        <div class="selectgroup">
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="split_opening_times" :value="true" class="selectgroup-input" v-model="model.split_opening_times">
              <span class="selectgroup-button">Oui</span>
            </ValidationProvider>
          </label>
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="split_opening_times" :value="false" class="selectgroup-input" v-model="model.split_opening_times">
              <span class="selectgroup-button">Non</span>
            </ValidationProvider>
          </label>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-center">
                    <th></th>
                    <th>
                      {{ translations.site_form.office_opening_hour_column }}
                      <span v-if="model.split_opening_times"><br>(matin)</span>
                    </th>
                    <th v-if="model.split_opening_times">
                      {{ translations.site_form.office_closing_hour_column }}
                      <span><br>(matin)</span>
                    </th>
                    <th v-if="model.split_opening_times">
                      {{ translations.site_form.office_opening_hour_column }}
                      <span><br>(après-midi)</span>
                    </th>
                    <th>
                      {{ translations.site_form.office_closing_hour_column }}
                      <span v-if="model.split_opening_times"><br>(après-midi)</span>
                    </th>
                    <th>{{ translations.site_form.office_open_column }}</th>
                    <th>{{ translations.site_form.office_without_appointment_column }}</th>
                    <th>{{ translations.site_form.office_closed_column }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(label, key) in translations.week_days">
                    <td class="text-right">
                      <div class="form-control-plaintext">{{ label }}</div>
                    </td>
                    <td>
                      <ValidationProvider immediate :rules="{ required: model.office_consultation && !model[`${key}_closed`] && !model[`${key}_open`], regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/ }" v-slot="{ invalid, touched, classes, errors }">
                        <input
                          type="text"
                          :name="`${key}_opening_time_am`"
                          class="form-control"
                          :class="invalid && classes"
                          :disabled="model[`${key}_closed`] || model[`${key}_open`]"
                          v-mask="'##:##'"
                          placeholder="HH:MM"
                          v-model="model[`${key}_opening_time_am`]">
                        <div class="invalid-feedback" v-show="touched">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </td>
                    <td v-if="model.split_opening_times">
                      <ValidationProvider immediate :rules="{ required: model.office_consultation && !model[`${key}_closed`] && !model[`${key}_open`], regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/ }" v-slot="{ invalid, touched, classes, errors }">
                        <input
                          type="text"
                          :name="`${key}_closing_time_am`"
                          class="form-control"
                          :class="invalid && classes"
                          :disabled="model[`${key}_closed`] || model[`${key}_open`]"
                          v-mask="'##:##'"
                          placeholder="HH:MM"
                          v-model="model[`${key}_closing_time_am`]">
                        <div class="invalid-feedback" v-show="touched">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </td>
                    <td v-if="model.split_opening_times">
                      <ValidationProvider immediate :rules="{ required: model.office_consultation && !model[`${key}_closed`] && !model[`${key}_open`], regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/ }" v-slot="{ invalid, touched, classes, errors }">
                        <input
                          type="text"
                          :name="`${key}_opening_time_pm`"
                          class="form-control"
                          :class="invalid && classes"
                          :disabled="model[`${key}_closed`] || model[`${key}_open`]"
                          v-mask="'##:##'"
                          placeholder="HH:MM"
                          v-model="model[`${key}_opening_time_pm`]">
                        <div class="invalid-feedback" v-show="touched">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </td>
                    <td>
                      <ValidationProvider immediate :rules="{ required: model.office_consultation && !model[`${key}_closed`] && !model[`${key}_open`], regex: /^(([0-1][0-9])|(2[0-3])):([0-5][0-9])$/ }" v-slot="{ invalid, touched, classes, errors }">
                        <input
                          type="text"
                          :name="`${key}_closing_time_pm`"
                          class="form-control"
                          :class="invalid && classes"
                          :disabled="model[`${key}_closed`] || model[`${key}_open`]"
                          v-mask="'##:##'"
                          placeholder="HH:MM"
                          v-model="model[`${key}_closing_time_pm`]">
                        <div class="invalid-feedback" v-show="touched">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </td>
                    <td class="text-center pt-4">
                      <label class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :name="`${key}_open`" :value="true" v-model="model[`${key}_open`]" @change="onDayOpen(key)">
                        <span class="custom-control-label"></span>
                      </label>
                    </td>
                    <td class="text-center pt-4">
                      <label class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :name="`${key}_without_appointment`" :value="true" v-model="model[`${key}_without_appointment`]"  @change="onDayWithoutAppointment(key)">
                        <span class="custom-control-label"></span>
                      </label>
                    </td>
                    <td class="text-center pt-4">
                      <label class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :name="`${key}_closed`" :value="true" v-model="model[`${key}_closed`]" @change="onDayClosed(key)">
                        <span class="custom-control-label"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" v-show="model.office_consultation">
        <h3 class="card-title">
          {{ translations.site_form.office_consultation_section_title }}
        </h3>
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.access_transportation_label }}
              </label>
              <ValidationProvider v-for="index in [0, 1, 2]" :key="index">
                <input
                  type="text"
                  class="form-control mb-2"
                  name="access_transportation"
                  v-model.trim="model.access_transportation[index]"
                  :placeholder="translations.site_form[`access_transportation_placeholder_${index}`]">
              </ValidationProvider>
           </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.access_information_label }}
              </label>
              <ValidationProvider v-for="index in [0, 1, 2]" :key="index">
                <input
                  type="text"
                  class="form-control mb-2"
                  name="access_information"
                  v-model.trim="model.access_information[index]"
                  :placeholder="translations.site_form[`access_information_placeholder_${index}`]">
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.access_label }}
              </label>
              <div class="custom-controls-stacked">
                <label class="custom-control custom-checkbox">
                  <ValidationProvider>
                    <input type="checkbox" class="custom-control-input" name="access_private_parking_lot" :value="true" v-model="model.access_private_parking_lot">
                    <span class="custom-control-label">
                      <i class="fa fa-fw fa-car mr-2"></i>
                      {{ translations.site_form.access_private_parking_lot_label }}
                    </span>
                  </ValidationProvider>
                </label>
                <label class="custom-control custom-checkbox">
                  <ValidationProvider>
                    <input type="checkbox" class="custom-control-input" name="access_handicapped" :value="true" v-model="model.access_handicapped">
                    <span class="custom-control-label">
                      <i class="fa fa-fw fa-wheelchair mr-2"></i>
                      {{ translations.site_form.access_handicapped_label }}
                    </span>
                  </ValidationProvider>
                </label>
                <label class="custom-control custom-checkbox">
                  <ValidationProvider>
                    <input type="checkbox" class="custom-control-input" name="access_ground_floor_or_elevator" :value="true" v-model="model.access_ground_floor_or_elevator">
                    <span class="custom-control-label">
                      <i class="fa fa-fw fa-building-o mr-2"></i>
                      {{ translations.site_form.access_ground_floor_or_elevator_label }}
                    </span>
                  </ValidationProvider>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <div class="form-group">
              <label class="form-label">
                {{ translations.site_form.access_public_parking_lot_label }}
              </label>
              <ValidationProvider>
                <input
                  type="text"
                  class="form-control"
                  name="access_public_parking_lot"
                  v-model.trim="model.access_public_parking_lot"
                  :placeholder="translations.site_form.access_public_parking_lot_placeholder">
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.home_consultation_section_title }}
        </h2>
      </div>
      <div class="card-body">
        <label class="form-label">
          {{ translations.site_form.home_consultation_label }}
        </label>
        <div class="selectgroup">
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="home_consultation" :value="true" class="selectgroup-input" v-model="model.home_consultation">
              <span class="selectgroup-button">Oui</span>
            </ValidationProvider>
          </label>
          <label class="selectgroup-item">
            <ValidationProvider>
              <input type="radio" name="home_consultation" :value="false" class="selectgroup-input" v-model="model.home_consultation">
              <span class="selectgroup-button">Non</span>
            </ValidationProvider>
          </label>
        </div>
      </div>
      <div class="card-body" v-show="model.home_consultation">
        <div class="row">
          <div class="col-sm-8">
            <label class="form-label">
              {{ translations.site_form.home_consultation_zones_section_title }}
              <span class="form-required">*</span>
              <i class="fe fe-help-circle" v-b-tooltip.hover :title="translations.site_form.home_consultation_zones_tooltip"></i>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" v-for="offset in [0, 5]" :key="offset">
            <div class="form-group">
              <ValidationProvider v-for="index in [0, 1, 2, 3, 4]" :key="index + offset" immediate :rules="{ required: ((index + offset == 0) && model.home_consultation) }" v-slot="{ invalid, classes, errors }">
                <input
                  type="text"
                  class="form-control mb-2"
                  name="home_intervention_zones"
                  v-model.trim="model.home_intervention_zones[index + offset]"
                  :placeholder="translations.site_form[`home_intervention_zones_placeholder_${index + offset}`]">
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="alert alert-icon alert-danger" v-show="homeInterventionZonesAlert">
          <i class="fe fe-alert-triangle"></i>
          {{ translations.site_form.home_intervention_zones_error_empty }}
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.summary_section_title }}
        </h2>
      </div>
      <div class="card-body pt-0">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{active: (model.summary_mode == 'summary_generated')}" @click="setSummaryMode('summary_generated')">
              {{ translations.site_form.summary_generated_tab_title }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{active: (model.summary_mode == 'summary_custom')}" @click="setSummaryMode('summary_custom')">
              {{ translations.site_form.summary_custom_tab_title }}
            </a>
          </li>
        </ul>
        <div class="mt-3" v-show="model.summary_mode == 'summary_generated'">
          <textarea class="form-control" rows="6" disabled>{{ summary }}</textarea>
        </div>
        <div v-show="model.summary_mode == 'summary_custom'">
          <div class="mt-3">
            <ValidationProvider>
              <textarea class="form-control" rows="6" v-model.trim="model.summary_custom" :placeholder="translations.site_form.summary_custom_placeholder" ref="summary"></textarea>
            </ValidationProvider>
          </div>
          <div class="alert alert-icon alert-primary mt-3">
            <i class="fe fe-alert-triangle"></i>
            <span v-html="translations.site_form.custom_summary_instructions_html"></span>
          </div>
        </div>
        <div class="mt-4">
          <em class="small">
            {{ translations.site_form.general_summary_instructions }}
          </em>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.seo_section_title }}
          <i class="fe fe-help-circle ml-1" v-b-tooltip.hover :title="translations.site_form.seo_section_tooltip"></i>
        </h2>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label class="form-label">
            {{ translations.site_form.seo_enabled_label }}
          </label>
          <div class="selectgroup">
            <label class="selectgroup-item">
              <ValidationProvider>
                <input type="radio" name="seo_enabled" :value="true" class="selectgroup-input" v-model="model.seo_enabled">
                <span class="selectgroup-button">Oui</span>
              </ValidationProvider>
            </label>
            <label class="selectgroup-item">
              <ValidationProvider>
                <input type="radio" name="seo_enabled" :value="false" class="selectgroup-input" v-model="model.seo_enabled">
                <span class="selectgroup-button">Non</span>
              </ValidationProvider>
            </label>
          </div>
        </div>
        <div v-if="model.seo_enabled">
          <div class="form-group">
            <label class="form-label">
              {{ translations.site_form.seo_description_label }}
              <span class="form-required">*</span>
              <i class="fe fe-help-circle ml-1" v-b-tooltip.hover :title="$root.interpolate(translations.site_form.seo_description_tooltip, { count: seoDescriptionMinimumCount })"></i>
            </label>
            <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
              <textarea class="form-control" rows="4" :class="invalid && classes" name="seo_description" v-model.trim="model.seo_description"></textarea>
              <div class="invalid-feedback">{{ errors[0] }}</div>
              <div v-if="model.seo_description && !invalid" class="mt-1">
                Longueur du texte&nbsp;:
                <strong :class="model.seo_description.length > seoDescriptionMinimumCount ? 'text-success' : 'text-warning'">
                  {{ model.seo_description.length }} caractère<span v-show="model.seo_description.length > 1">s</span>
                </strong>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label">
              {{ translations.site_form.seo_meta_description_label }}
              <span class="form-required">*</span>
              <i class="fe fe-help-circle ml-1" v-b-tooltip.hover :title="$root.interpolate(translations.site_form.seo_meta_description_tooltip, { count: seoMetaDescriptionMinimumCount })"></i>
            </label>
            <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
              <textarea class="form-control" rows="4" :class="invalid && classes" name="seo_meta_description" v-model.trim="model.seo_meta_description"></textarea>
              <div class="invalid-feedback">{{ errors[0] }}</div>
              <div v-if="model.seo_meta_description && !invalid" class="mt-1">
                Longueur du texte&nbsp;:
                <strong :class="model.seo_meta_description.length > seoMetaDescriptionMinimumCount ? 'text-success' : 'text-warning'">
                  {{ model.seo_meta_description.length }} caractère<span v-show="model.seo_meta_description.length > 1">s</span>
                </strong>
              </div>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label class="form-label">
              {{ translations.site_form.seo_keywords_label }}
              <span class="form-required">*</span>
              <i class="fe fe-help-circle ml-1" v-b-tooltip.hover :title="$root.interpolate(translations.site_form.seo_keywords_tooltip, { count: seoKeywordsMinimumCount })"></i>
            </label>
            <ValidationProvider rules="required" v-slot="{ invalid, classes, errors }">
              <input type="text" class="form-control" :class="invalid && classes" name="seo_keywords" v-model.trim="model.seo_keywords"></textarea>
              <div class="invalid-feedback">{{ errors[0] }}</div>
              <div v-if="model.seo_keywords && !invalid" class="mt-1">
                Longueur du texte&nbsp;:
                <strong :class="model.seo_keywords.length < seoKeywordsMinimumCount ? 'text-success' : 'text-warning'">
                  {{ model.seo_keywords.length }} caractère<span v-show="model.seo_keywords.length > 1">s</span>
                </strong>
              </div>
            </ValidationProvider>
          </div>
          <div class="mt-4">
            <em class="small" v-html="translations.site_form.seo_footer_instructions_html"></em>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-status card-status-left bg-blue"></div>
      <div class="card-header">
        <h2 class="card-title">
          {{ translations.site_form.theme_section_title }}
        </h2>
      </div>
      <div class="card-alert alert alert-warning" v-show="editThemeUrl != null && dirty">
        <i class="fa fa-info-circle mr-2"></i>
        {{ translations.site_form.theme_editing_pending_changes }}
      </div>
      <div class="card-alert alert alert-warning" v-show="editThemeUrl == null">
        <i class="fa fa-info-circle mr-2"></i>
        {{ translations.site_form.theme_editing_site_incomplete }}
      </div>
      <div class="card-body">
        <button class="btn btn-lg btn-primary" :disabled="submitDisabled()" @click="submitVersion({ redirect: editThemeUrl })">
          {{ model.theme_updated_at ? translations.site_form.theme_edit_button : translations.site_form.theme_create_button }}
        </button>
      </div>
    </div>
    <div class="btn-list text-center my-8">
      <button class="btn btn-lg btn-primary" :disabled="submitDisabled()" @click.prevent="submitVersion()">
        {{ submitting ? translations.site_form.busy_button : translations.site_form.submit_button }}
      </button>
    </div>
  </ValidationObserver>
</template>

<script>
  import axios from 'axios'
  import { extend, configure, ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, email, regex } from 'vee-validate/dist/rules'
  import { mask } from 'vue-the-mask'
  import _ from 'lodash'
  import { BTabs, BTab, VBPopover, VBTooltip } from 'bootstrap-vue'
  import AvatarForm from './AvatarForm'

  extend('required', {
    ...required,
    message: 'Ce champ est obligatoire.'
  })
  extend('email', {
    ...email,
    message: 'Email invalide.'
  })
  extend('regex', {
    ...regex,
    message: 'Format invalide.'
  })
  configure({
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    }
  })

  export default {
    components: {
      AvatarForm,
      ValidationProvider,
      ValidationObserver,
      BTabs,
      BTab,
      VBPopover
    },
    directives: {
      mask,
      'b-tooltip': VBTooltip
    },
    props: {
      translations: Object,
      versionsUrl: String,
      version: Object,
      defaultAvatarUrl: String,
      avatarUrl: String,
      directUploadsUrl: String,
      editThemeUrl: String,
      seoDescriptionMinimumCount: Number,
      seoMetaDescriptionMinimumCount: Number,
      seoKeywordsMinimumCount: Number
    },
    data() {
      return {
        model: this.version,
        submitting: false
      }
    },
    created() {
      window.addEventListener('beforeunload', (e) => {
        if (this.$refs.validationObserver.flags.dirty && !this.submitting) {
          e.preventDefault()
          e.returnValue = ''
        }
      })
    },
    computed: {
      homeInterventionZonesAlert() {
        return _.compact(this.model.home_intervention_zones).length == 0
      },
      languagesAlert() {
        return this.model.languages.length == 0
      },
      paymentModesAlert() {
        return this.model.payment_modes.length == 0
      },
      withoutAppointments() {
        return [
          this.model.mon_without_appointment,
          this.model.tue_without_appointment,
          this.model.wed_without_appointment,
          this.model.thu_without_appointment,
          this.model.fri_without_appointment,
          this.model.sat_without_appointment,
          this.model.sun_without_appointment
        ]
      },
      summary() {
        let parts = []
        parts.push(`${this.model.nurse_first_name || '{Prénom}'} ${this.model.nurse_last_name || '{Nom}'},`)
        if (this.model.nurse_gender == 'female') {
          parts.push("infirmière libérale")
        } else {
          parts.push("infirmier libéral")
        }
        parts.push("IDEL, réalise des soins infirmiers")
        if (this.model.home_consultation) {
          parts.push("à domicile")
          if (this.model.office_consultation) {
            parts.push("et")
          }
        }
        if (this.model.office_consultation) {
          parts.push("au cabinet")
        }
        if (_.some(this.withoutAppointments)) {
          if (_.every(this.withoutAppointments)) {
            parts.push("sans rendez-vous.")
          } else {
            parts.push("sans rendez-vous et sur rendez-vous.")
          }
        } else {
          parts.push("sur rendez-vous.")
        }
        parts.push("\n\nTitulaire d'un diplôme d'état infirmier,")
        if (this.model.approved) {
          parts.push("et")
          if (this.model.nurse_gender == 'female') {
            parts.push("conventionnée")
          } else {
            parts.push("conventionné")
          }
          parts.push("avec l'assurance maladie,")
        }
        if (this.model.nurse_gender == 'female') {
          parts.push("elle")
        } else {
          parts.push("il")
        }
        parts.push("réalise tous types de soins médicalement prescrits")
        if (this.model.home_consultation) {
          parts.push("directement au domicile du patient")
          if (this.model.office_consultation) {
            parts.push("et")
          }
        }
        if (this.model.office_consultation) {
          parts.push("à son cabinet")
        }
        return parts.join(" ") + '.'
      }
    },
    methods: {
      submitDisabled() {
        return (
          this.model.languages.length == 0 ||
          this.model.payment_modes.length == 0 ||
          (this.$refs.validationObserver && this.$refs.validationObserver.flags.invalid) ||
          this.submitting
        )
      },
      setSummaryMode(mode) {
        if (mode == 'summary_custom') {
          this.model.summary_mode = mode
          this.model.summary_custom = this.summary
          this.$nextTick(() => this.$refs.summary.focus())
        } else {
          this.$bvModal.msgBoxConfirm(this.translations.site_form.summary_generated_loss_instructions, {
            title: this.translations.site_form.summary_generated_loss_title,
            okVariant: 'primary',
            okTitle: this.translations.site_form.summary_generated_loss_confirm_button,
            cancelTitle: this.translations.site_form.summary_generated_loss_cancel_button,
            hideHeaderClose: true,
            centered: true
          }).then(confirmed => {
            if (confirmed) {
              this.model.summary_mode = mode
            } else {
              this.model.summary_mode = 'summary_custom'
            }
          })
        }
      },
      onDayWithoutAppointment(key) {
        if (this.model[`${key}_without_appointment`]) {
          this.model[`${key}_closed`] = false
          this.model[`${key}_open`] = false
        }
      },
      onDayClosed(key) {
        if (this.model[`${key}_closed`]) {
          this.model[`${key}_opening_time_am`] = null
          this.model[`${key}_closing_time_am`] = null
          this.model[`${key}_opening_time_pm`] = null
          this.model[`${key}_closing_time_pm`] = null
          this.model[`${key}_without_appointment`] = false
          this.model[`${key}_open`] = false
        }
      },
      onDayOpen(key) {
        if (this.model[`${key}_open`]) {
          this.model[`${key}_opening_time_am`] = null
          this.model[`${key}_closing_time_am`] = null
          this.model[`${key}_opening_time_pm`] = null
          this.model[`${key}_closing_time_pm`] = null
          this.model[`${key}_without_appointment`] = false
          this.model[`${key}_closed`] = false
        }
      },
      submitVersion(options = {}) {
        if (this.submitting) return
        this.submitting = true
        this.model.summary_generated = this.summary
        axios.post(this.versionsUrl, {
          ...options,
          version: this.model
        }).then(response => {
          document.location = response.headers.location
        }).catch(error => {
          console.error(error)
          this.submitting = false
        })
      }
    }
  }
</script>
