<template>
  <div class="card">
    <div class="card-status card-status-left bg-blue"></div>
    <div class="card-header">
      <h3 class="card-title d-flex flex-row align-items-center">
        {{ translations.subscription_form.title }}
        <span class="badge badge-success ml-4" v-if="subscription && subscription.trial">
          {{ translations.subscription_form.status_trial }}
        </span>
        <span class="badge badge-success ml-4" v-else-if="subscription && subscription.status == 'activated'">
          {{ translations.subscription_form.status_activated }}
        </span>
        <span class="badge badge-danger ml-4" v-else-if="subscription && subscription.status == 'canceled'">
          {{ translations.subscription_form.status_canceled }}
        </span>
        <span class="badge badge-danger ml-4" v-else-if="subscription && subscription.status == 'expired'">
          {{ translations.subscription_form.status_expired }}
        </span>
        <span class="badge badge-danger ml-4" v-else-if="subscription && subscription.status == 'refused'">
          {{ translations.subscription_form.status_refused }}
        </span>
        <span class="badge badge-danger ml-4" v-else-if="subscription && subscription.status == 'pending'">
          {{ translations.subscription_form.status_pending }}
        </span>
      </h3>
    </div>
    <div class="card-alert alert alert-primary" v-show="siteEditUrl && subscription && subscription.status == 'activated'">
      <i class="fa fa-info-circle mr-1"></i>
      <span v-html="$root.interpolate(translations.subscription_form.activated_instructions, { href: siteEditUrl })"></span>
    </div>
    <div class="card-alert alert alert-warning" v-show="busy && subscription == null">
      <i class="fa fa-refresh fa-spin mr-1"></i>
      {{ translations.subscription_form.processing_instructions }}
    </div>
    <div class="card-body" v-show="display == 'prompt'">
      <div v-if="subscription && subscription.status == 'activated'">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-4">
              {{ translations.subscription_form.amount_label }}&nbsp;:
              <strong>{{ price | amount }} TTC</strong>
            </div>
            <i class="payment mr-2" :class="`payment-${cardBrand}`"></i>
            <b>{{ cardNumber }}</b>
          </div>
          <div class="col-md-6 text-right">
            <div class="btn-list mt-4">
              <button class="btn btn-outline-secondary" @click="display = 'form'">
                <i class="fe fe-edit mr-1"></i>
                {{ translations.subscription_form.edit_button }}
              </button>
              <button class="btn btn-outline-danger" @click="cancel" v-if="subscription.status == 'activated'">
                <i class="fe fe-x-circle"></i>
                {{ translations.subscription_form.terminate_button }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center" v-else>
        <div>
          {{ translations.subscription_form.payment_mode_missing }}
        </div>
        <div class="btn-list ml-auto">
          <button class="btn btn-primary" @click="display = 'form'">
            <i class="fe fe-edit mr-1"></i>
            {{ translations.subscription_form.subscribe_button }}
          </button>
        </div>
      </div>
    </div>
    <div class="card-body" v-show="display == 'form'">
      <fieldset class="form-fieldset">
        <card
          class="stripe-card"
          :class="{ complete: stripeComplete }"
          :stripe="stripeApiKey"
          :options="{ hidePostalCode: true }"
          @change="stripeComplete = $event.complete">
        </card>
      </fieldset>
      <div class="text-right mt-2">
        <strong v-if="trial">
          {{ $root.interpolate(translations.subscription_form.trial_helper, { amount: $options.filters.Amount(price) }) }}
        </strong>
        <strong v-else>
          {{ $root.interpolate(translations.subscription_form.amount_helper, { amount: $options.filters.Amount(price) }) }}
        </strong>
      </div>
      <div class="btn-list text-right mt-6">
        <button class="btn btn-secondary" @click="display = 'prompt'">
          {{ translations.subscription_form.cancel_button }}
        </button>
        <button class="btn btn-primary" @click="pay" :disabled="submitDisabled">
          <i class="fe fe-check mr-1"></i>
          {{ busy ? translations.subscription_form.processing_button : translations.subscription_form.submit_button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import { Card, createToken } from "vue-stripe-elements-plus"
  import Amount from '../filters/Amount'

  export default {
    components: {
      Card
    },
    filters: {
      Amount
    },
    props: {
      translations: Object,
      url: String,
      price: Number,
      stripeApiKey: String,
      siteEditUrl: String,
      trial: Boolean,
      gtmId: String
    },
    data() {
      return {
        subscription: null,
        display: 'prompt',
        busy: false,
        stripeComplete: false
      }
    },
    computed: {
      cardBrand() {
        if (this.subscription && this.subscription.stripe_card) {
          return this.subscription.stripe_card.brand.toLowerCase().replaceAll(' ', '')
        }
      },
      cardNumber() {
        if (this.subscription && this.subscription.stripe_card) {
          return `**** **** **** ${this.subscription.stripe_card.last4}`
        }
      },
      submitDisabled() {
        return !this.stripeComplete || this.busy
      }
    },
    created() {
      this.fetch()
    },
    methods: {
      fetch() {
        axios.get(
          this.url
        ).then(response => {
          const previousStatus = (response.data.subscription ? response.data.subscription.status : undefined)
          this.subscription = response.data.subscription
          if (previousStatus === 'activated') {
            this.subscription.status = 'activated'
          }
        })
      },
      pay() {
        if (this.subscription) {
          this.update()
        } else {
          this.create()
        }
      },
      async create() {
        if (this.busy) return
        this.busy = true
        const data = await createToken()
        if (data.token) {
          try {
            const response = await axios.post(
              this.url,
              { stripe_token: data.token.id }
            )
            const paymentIntentClientSecret = response.data.payment_intent_client_secret
            const pendingSetupIntentClientSecret = response.data.pending_setup_intent_client_secret
            if (paymentIntentClientSecret || pendingSetupIntentClientSecret) {
              let result
              const stripe = window.Stripe(this.stripeApiKey)
              if (paymentIntentClientSecret) {
                result = await stripe.confirmCardPayment(paymentIntentClientSecret)
              } else {
                // https://stripe.com/docs/billing/subscriptions/payment#authentication-failures
                result = await stripe.confirmCardSetup(pendingSetupIntentClientSecret)
              }
              if (result.error) {
                console.error(result.error)
                this.busy = false
                this.$root.toast(this.translations.subscription_form.stripe_payment_error_toast, { variant: 'danger' })
                return
              }
            }
            let retryCount = 0
            do {
              await this.sleep(5000)
              this.fetch()
              ++retryCount
            } while ((!this.subscription || !this.subscription.stripe_card) && (retryCount < 5))
            this.$root.toast(this.translations.subscription_form.stripe_payment_success_toast, { variant: 'success' })
            if (typeof(fbq) !== 'undefined') {
              fbq('track', 'Purchase', { currency: "EUR", value: this.price })
            }
            if (this.gtmId) {
              gtag('set', 'linker', { 'domains': ['albus.fr', 'moncabinetenligne.com'] })
              gtag('event', 'conversion', { 'send_to': `${this.gtmId}/6go5CP6nheABEPbJpdIC`, 'value': this.price, 'currency': 'EUR' })
            }
          } catch (error) {
            console.error(error)
            if (error.response && error.response.data && error.response.data.errors) {
              this.$root.toast(error.response.data.errors[0], { variant: 'danger' })
            } else {
              this.$root.toast("Une erreur est apparue, veuillez réessayer ultérieurement.", { variant: 'danger' })
            }
            this.fetch()
          }
        } else if (data.error) {
          console.error(data.error)
          this.$root.toast(data.error.message, { variant: 'danger' })
        } else {
          this.$root.toast(this.translations.subscription_form.stripe_payment_error_toast, { variant: 'danger' })
        }
        this.display = 'prompt'
        this.busy = false
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
      },
      async update() {
        if (this.busy) return
        if (this.subscription.status === 'pending' || this.subscription.status === 'canceled' || this.subscription.status === 'refused' || this.subscription.status === 'expired') {
          this.subscription = null
          return await this.create()
        }
        this.busy = true
        const data = await createToken()
        if (data.token) {
          try {
            const response = await axios.put(
              this.url,
              { stripe_token: data.token.id }
            )
            this.fetch()
            this.$root.toast(this.translations.subscription_form.stripe_payment_mode_updated_success_toast)
          } catch (e) {
            console.error(e)
            this.$root.toast(e.message, { variant: 'danger' })
          }
        } else if (data.error) {
          console.error(data.error)
          this.$root.toast(data.error.message, { variant: 'danger' })
        } else {
          this.$root.toast(this.translations.subscription_form.stripe_unknown_error_toast, { variant: 'danger' })
        }
        this.display = 'prompt'
        this.busy = false
      },
      async cancel() {
        if (this.busy) return
        this.$bvModal.msgBoxConfirm(this.translations.subscription_form.terminate_modal_instructions, {
          title: this.translations.subscription_form.terminate_modal_title,
          okVariant: 'danger',
          okTitle: this.translations.subscription_form.terminate_modal_confirm_button,
          cancelTitle: this.translations.subscription_form.terminate_modal_cancel_button,
          hideHeaderClose: true,
          centered: true
        }).then(confirmed => {
          if (confirmed) {
            this.busy = true
            axios.put(
              `${this.url}/cancel`
            ).then(response => {
              this.fetch()
              this.display = 'prompt'
              this.busy = false
              this.$root.toast(this.translations.subscription_form.terminated_toast)
            })
          }
        })
      }
    }
  }
</script>
