<template>
  <div>
    <label v-b-tooltip.hover title="Changer de photo de profil" style="cursor: pointer;">
      <img ref="avatar" class="rounded-circle border border-secondary shadow-sm" :src="src || defaultSrc" style="width: 100px; height: 100px;">
      <input ref="input" type="file" class="sr-only" accept="image/*" @change="fileChanged">
    </label>
    <div class="mt-1">
      <button type="button" class="btn btn-sm btn-secondary" @click.stop="$refs.input.click()">
        Modifier
      </button>
      <button type="button" :disabled="removeDisabled" class="btn btn-sm btn-secondary text-danger" @click.stop="remove()">
        Supprimer
      </button>
    </div>
    <b-modal
      ref="modal"
      centered
      no-close-on-backdrop
      title="Photo de profil"
      ok-title="Valider"
      cancel-title="Annuler"
      @shown="initCropper"
      @hidden="closeCropper"
      @ok="submit">
      <div>
        <img ref="preview" class="img-full" :src="previewSrc">
      </div>
    </b-modal>
  </div>
</template>

<script>
  import { DirectUpload } from "@rails/activestorage"
  import { BModal, VBTooltip } from 'bootstrap-vue'
  import Cropper from 'cropperjs'
  export default {
    directives: {
      'b-tooltip': VBTooltip
    },
    props: {
      src: String,
      defaultSrc: String,
      uploadUrl: String
    },
    data() {
      return {
        cropper: null,
        previewSrc: null,
        filename: null,
        removeDisabled: (this.src === undefined)
      }
    },
    methods: {
      fileLoaded(src) {
        this.previewSrc = src
        this.$refs.input.value = ''
        this.$refs.modal.show()
      },
      fileChanged(event) {
        const files = event.target.files
        if (files && files.length > 0) {
          const file = files[0]
          this.filename = file.name
          if (URL) {
            this.fileLoaded(URL.createObjectURL(file))
          } else if (FileReader) {
            const reader = new FileReader()
            reader.onload = (e) => this.fileLoaded(reader.result)
            reader.readAsDataURL(file)
          }
        }
      },
      initCropper() {
        this.cropper = new Cropper(this.$refs.preview, {
          aspectRatio: 1,
          viewMode: 1
        })
      },
      closeCropper() {
        this.cropper.destroy()
        this.cropper = null
      },
      remove() {
        this.$refs.avatar.src = this.defaultSrc
        this.$emit('input', null)
        this.removeDisabled = true
      },
      submit() {
        if (!this.cropper) return
        this.$emit('uploading')
        const canvas = this.cropper.getCroppedCanvas({
          width: 320,
          height: 320
        })
        this.$refs.avatar.src = canvas.toDataURL()
        canvas.toBlob((blob) => {
          blob.lastModifiedDate = new Date()
          blob.name = this.filename || 'avatar'
          const upload = new DirectUpload(blob, this.uploadUrl)
          upload.create((error, result) => {
            if (error) {
              console.error(error)
            } else {
              this.$emit('input', result.signed_id)
              this.$emit('uploaded')
              this.removeDisabled = false
            }
          })
        })
      }
    }
  }
</script>
